import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import Hero from '../components/hero';

function Products({}) {
  return (
    <Layout>
      <Hero
        title="Products"
        subtitle="Stylish &amp; Affordable Retail Optical Boutique"
        color="light"
      />
      <section className="section content">
        <div className="container">
          <h3 className="title heading is-5">Eyewear</h3>
          <p>
            At Boroyan Eye Associates, we take time to explain your vision care to you and offer
            innovative and simple ways to make your days easier with proper eyewear. The products we
            use are of excellent quality and our office optician, Doreen, has had many years working
            with patients to aid in making sound decisions and proper fitting.
          </p>
          <ul>
            <li>Complete eyewear services</li>
            <li>Unique European optical boutique</li>
            <li>Licensed optician on site</li>
            <li>Computerized optical laboratory on premises</li>
            <li>Children’s eyeglass center</li>
            <li>Prescriptions accepted/filled</li>
            <li>Competitively priced top quality lenses and materials utilized</li>
          </ul>

          <h3 className="title heading is-5">Contact Lenses</h3>
          <p>
            No matter if you wear contact lenses for sports, socially or throughout your days, a
            proper contact lens fit and top quality lenses are important to ensuring optimal
            performance. Boroyan Eye Associates is known throughout the community for their ability
            to fit contact lenses. We enjoy working with patients of all ages and of all vision
            types.
          </p>
          <ul>
            <li>Major interest of our practice</li>
            <li>Routine and Astigmatism</li>
            <li>Bifocal- See clearly at all distances</li>
            <li>Challenging prescriptions</li>
            <li>All age groups, with special attention to our younger patients</li>
            <li>No obligation evaluations</li>
            <li>Competitive pricing</li>
          </ul>
        </div>
      </section>
    </Layout>
  );
}

Products.propTypes = {};

export default Products;

export const query = graphql`
  query {
    andrea: file(relativePath: { eq: "andrea-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    henry: file(relativePath: { eq: "henry-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    doreen: file(relativePath: { eq: "doreen-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
